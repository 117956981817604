import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EnumTipologiaConsumoPasto, GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta',
  templateUrl: './strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta.component.html',
  styleUrls: ['./strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta.component.scss'],
})
export class StrumentoRegistrazioneGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEtaComponent implements OnInit, OnChanges {

  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[];
  @Output() updatedStrumentiDiRegistrazioneArchivio = new EventEmitter();

  displayedColumns: string[] = ['bambino', 'assente', 'primo', 'secondo', 'contorno', 'frutta', 'merenda', 'sonnoPomeriggioDalle', 'sonnoPomeriggioAlle', 'feci', 'note'];
  arrayConsumoPasto: string[] = [
    EnumTipologiaConsumoPasto.Assaggiato,
    EnumTipologiaConsumoPasto.InParte,
    EnumTipologiaConsumoPasto.Meta,
    EnumTipologiaConsumoPasto.NonConsumato,
    EnumTipologiaConsumoPasto.PastoNonPresente,
    EnumTipologiaConsumoPasto.Tutto];
  formGruop: FormGroup;
  row;
  constructor(
    private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.formGruop = this.fb.group({
      rows: this.fb.array([]),
    });
    this.strumentiDiRegistrazioneArchivio.sort((a, b) => a.personeCoinvolte[0].cognome > b.personeCoinvolte[0].cognome ? 1 : -1);

    this.strumentiDiRegistrazioneArchivio.forEach((strumento, index) => {
      let content: GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta = null;
      if (strumento.content) {
        content = strumento.content as GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta;
      } else {
        content = new GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta();
      }

      content.assente ? content.assente = true : content.assente = false;

      const bambino = strumento.personeCoinvolte[0];
      this.formArray.push(this.fb.group({
        bambino: `${bambino.nome} ${bambino.cognome}`,
        assente: content.assente,
        primo: content.primo,
        secondo: content.secondo,
        contorno: content.contorno,
        frutta: content.frutta,
        merenda: content.merenda,
        sonnoPomeriggioDalle: content.sonnoPomeriggioDalle,
        sonnoPomeriggioAlle: content.sonnoPomeriggioAlle,
        feci: content.feci,
        note: content.note,
      }));

      const row = this.formArray.get(`${index}`);
      content.assente ? row.disable() : row.enable();

    });
    this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());

    this.formArray.valueChanges.subscribe(() => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }
  ngOnChanges() {
  }

  get formArray() {
    return <FormArray>this.formGruop.get('rows');
  }

  changeCheckBox(event, index) {
    this.row = this.formArray.get(`${index}`);
    // this.row.valueChanges.subscribe((el) => el.assente = event.checked);
    if (event.checked) {
      this.formArray.get(`${index}`).get('assente').setValue(event.checked);
      // this.strumentiDiRegistrazioneArchivio[index].content.assente = event.checked;
      this.row.disable();
    } else {
      this.formArray.get(`${index}`).get('assente').setValue(event.checked);
      // this.strumentiDiRegistrazioneArchivio[index].content.assente = event.checked;
      this.row.enable();
    }
  }

  buildStrumentoForm() {

    this.strumentiDiRegistrazioneArchivio.forEach((strumento, index) => {
      const form = this.formArray.getRawValue()[index];

      delete form.bambino;
      strumento.content = form;
    });

    return this.strumentiDiRegistrazioneArchivio;
  }

}
