import { HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injectable,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  translocoConfig,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

import { CONFIGURATION } from './models/configuration.injection-token';
import { Configuration } from './models/configuration.interface';
import { CustomMatPaginatorIntlService } from './services/custom-mat-paginator-intl.service';
import { Language } from './state/language.model';
import { LanguagesService } from './state/languages.service';

export function preloadLanguages(languagesService: LanguagesService) {
  return function () {
    const defaultLanguages: Language[] = [
      {
        id: 'it-IT',
        label: 'Italiano (IT)',
      },
      {
        id: 'en-US',
        label: 'English (US)',
      },
      {
        id: 'fr-FR',
        label: 'Français (FR)',
      },
      {
        id: 'pt-BR',
        label: 'Português (PT)',
      }
    ];

    languagesService.store.upsertMany(defaultLanguages);
    if (!languagesService.query.hasActive()) {
      languagesService.store.setActive(defaultLanguages[0].id);
    }
  };
}

export function loadLanguages(
  lblAppUtilLanguageConfiguration: Configuration,
  languagesService: LanguagesService,
) {
  const languages = languagesService.query.getAll();
  const activeLanguage = languagesService.query.getActive() || languages[0];

  return translocoConfig({
    availableLangs: languages,
    defaultLang: activeLanguage.id,
    // Remove this option if your application
    // doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: lblAppUtilLanguageConfiguration.prodMode,
  });
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(localeId: string) {
    return this.http.get<Translation>(`./assets/i18n/${localeId}.json`);
  }
}

@NgModule({
  imports: [TranslocoLocaleModule.init()],
})
export class LblAppUtilLanguagesModule {
  static forRoot(configuration: Configuration): ModuleWithProviders {
    return {
      ngModule: LblAppUtilLanguagesModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: preloadLanguages,
          deps: [LanguagesService],
        },
        {
          provide: CONFIGURATION,
          useValue: configuration,
        },
        {
          provide: TRANSLOCO_CONFIG,
          useFactory: loadLanguages,
          deps: [CONFIGURATION, LanguagesService],
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService },
      ],
    };
  }
}
